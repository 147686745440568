<template>
    <div id='building' ondragstart="return false">
      <div id="Back" style="height:66px">
        <img src="../assets/backToHome.png" alt="backToHome" @click="Back()" style="position:absolute;left:20px">
      </div>
      <div id="title">
        <img src="../assets/ProjectTitle.png" alt="ProjectTitle">
      </div>
      <div id="words">
        <div id="wordsDetail" style="margin-left:20%;margin-right:20%;height:auto">
          &#160;&#160;&#160;&#160;产品线：企业可以开发一系列与茶文化相关的产品，如采用龙鳞装的元素设计的茶叶包装、茶具、茶文化书籍等。这些产品既具有实用性，又兼具艺术性和文化价值，能够满足消费者对高品质茶文化生活的追求。
          <br><br>&#160;&#160;&#160;&#160;品牌定位：企业可以定位为高端茶文化品牌，以传承和发扬中华传统文化为己任，致力于为消费者提供高品质的茶文化产品和服务。品牌形象可以融合龙鳞装的古朴典雅和茶文化的宁静致远，形成独特的品牌魅力。
          <br><br>&#160;&#160;&#160;&#160;市场营销：企业可以通过线上线下相结合的方式，进行产品推广和品牌宣传。线上方面，可以利用社交媒体、短视频平台等网络渠道，通过内容营销、直播带货等方式吸引消费者关注；线下方面，可以在茶文化场馆、高端商场等场所设立展示厅或专卖店，让消费者亲身体验产品的独特魅力。
          <br><br>&#160;&#160;&#160;&#160;社会责任：利用龙鳞装这种独特的装帧形式，为乡村茶叶设计精美的包装，提升茶叶的品牌形象和附加值，并且通过推广和销售这些包装精美的乡村茶叶，增加茶农的经济收入，助力当地经济的发展和脱贫攻坚工作。将龙鳞装技艺作为非物质文化遗产进行保护和传承，通过举办展览、研讨会、培训班等活动，提高公众对龙鳞装的认识和重视程度。与茶文化相结合，开展非遗保护和传承的公益项目，如茶艺表演、非遗体验等，让更多人亲身感受非遗文化的魅力，增强对非遗的保护意识。支持和培养龙鳞装技艺的传承人，为他们的创作和传承提供必要的资金和资源支持，确保这一非遗技艺能够得以延续和发展。
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'EnterpriseView',
  components: {
  },
  methods: {
    Back () {
      this.$router.replace('/')
    }
  }
}
</script>

<style scoped>
#building{
  background:url("../assets/backgroundImage.png");
  width:100%;
  height:100%;
  background-size:100% 100%;
}

#title{
  align-items: center;
  justify-content: center;
}

#words{
  align-items: center;
  justify-content: center;
  padding-bottom: 10%;
}

#wordsDetail{
  font-family: 'KaiTi';
  font-size: 30px;
  font-weight: 500;
  color: black;
  text-align: start;
}
</style>
