<template>
  <div id='building' ondragstart="return false">
    <div id="title">
      <img src="../assets/HomeTitle.png" alt="HomeTitle">
    </div>
    <div id="Details">
      <div id="Link">
        <img class="LinkItems" id="Enterprise" src="../assets/EnterpriseIntroduction.png" alt="EnterpriseIntroduction" v-on:click="Jump('/EnterpriseIntroduction')">
        <img class="LinkItems" id="Project" src="../assets/ProjectIntroduction.png" alt="ProjectIntroduction" v-on:click="Jump('/ProjectIntroduction')">
        <img class="LinkItems" id="Product" src="../assets/ProductionExhibition.png" alt="ProductExhibition" v-on:click="Jump('/')">
        <img class="LinkItems" id="Contact" src="../assets/ContactUs.png" alt="ContactUs" v-on:click="Jump('/')">
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
  },
  methods: {
    Jump (pagePath) {
      console.log('Jump:', pagePath)
      this.$router.replace(pagePath)
    }
  }
}
</script>

<style scoped>
#building{
  background:url("../assets/backgroundImage.png");
  width:100%;
  height:100%;
  position:fixed;
  background-size:100% 100%;

}

#title{
  position: absolute;
  top: 10px;
  left: 40%;
  transform: translate(-50%,10%);
}

#Details{
  display: flex;
  align-items:center;
  justify-content: center;
  transform: translate(0,50%);
}

#Link{
  display: flex;
  flex-direction: row;
  transform: translate(-25%, 35%);
}

.LinkItems{
  margin: 50px
}

#Project{
  transform: translate(0,-60px);
}

#Product{
  transform: translate(0,-20px);
}

#Contact{
  transform: translate(0,-80px);
}
</style>
