<template>
  <div id='building' ondragstart="return false">
    <div id="Back" style="height:66px">
      <img src="../assets/backToHome.png" alt="backToHome" @click="Back()" style="position:absolute;left:20px">
    </div>
    <div id="title">
      <img src="../assets/EnterpriseTitle.png" alt="EnterpriseTitle" style="left: 50%;">
    </div>
    <div id="words">
      <div id="wordsDetail" style="margin-left:20%;margin-right:20%;height:auto">
        &#160;&#160;&#160;&#160;我们以尊重和保护非遗为核心，致力于将悠久的茶文化通过龙鳞装这一独特的艺术形式传承下去。我们坚信，通过创新的方式将传统文化与现代审美相结合，能够让更多的人领略到茶文化的魅力，同时也让龙鳞装这一古老技艺焕发新的生机。我们的使命不仅是提供优质的茶产品和独特的文化体验，更是要引领大众共同参与到非遗的保护与传承中来，让这份宝贵的文化遗产得以延续。
        <br><br>
        &#160;&#160;&#160;&#160;茶文化，作为中华民族千年传承的瑰宝，不仅代表着一种生活方式，更承载着深厚的文化底蕴。然而，在现代社会快节奏的生活中，许多人逐渐遗忘了茶文化的魅力。为了传承和弘扬这一传统文化，我们企业用“龙鳞装”——一款集文化、美学于一体的综合性茶文化推广非遗产品，其具有广阔的市场前景和巨大的发展潜力。我们希望将茶文化与龙鳞装结合，让人们在品味茶香的同时，感受茶感受龙鳞装的魅力。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EnterpriseView',
  components: {
  },
  methods: {
    Back () {
      this.$router.replace('/')
    }
  }
}
</script>

<style scoped>
#building{
  background:url("../assets/backgroundImage.png");
  width:100%;
  height:100%;
  background-size:100% 100%;
}

#title{
  align-items: center;
  justify-content: center;
}

#words{
  align-items: center;
  justify-content: center;
  padding-bottom: 10%;
}

#wordsDetail{
  font-family: 'KaiTi';
  font-size: 30px;
  font-weight: 500;
  color: black;
  text-align: start;
}
</style>
