import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EnterpriseView from '../views/EnterpriseView.vue'
import ProjectIntroductionView from '@/views/ProjectIntroductionView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/EnterpriseIntroduction',
    name: 'enterprise',
    component: EnterpriseView
  },
  {
    path: '/ProjectIntroduction',
    name: 'project',
    component: ProjectIntroductionView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
